<template>
  <div v-if="params && params.data && params.data.surveys">
    <div
      v-for="survey in params.data.surveys"
      :key="survey.uuid"
    >
      <div class="font-bold">{{ survey.question }}</div>
      <div
        v-for="option in survey.options"
        :key="option.uuid"
        class="flex items-center justify-start w-2/3 space-x-8"
      >
        <div class="w-1/3 bg-gray-200 rounded-full h-3 relative">
          <div
            class="bg-primary-200 h-3 rounded-full"
            :style="'width:' + calculateVotingRate(option, survey.options) + '%'"
          >
          </div>
          <div class="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center text-xs">
            {{ calculateVotingRate(option, survey.options) }}%
          </div>
        </div>
        <div class="w-1/2 flex space-x-2">
          <t-tag
            tag-name="div"
            variant="badge"
          >
            {{ option.answers_count }} votes
          </t-tag>
          <div class="text-sm">
            {{ option.option }}
          </div>
        </div>
      </div>
      <div v-if="survey.custom_answers && survey.custom_answers.length">
        <div class="font-bold mt-4">Réponses personnalisées</div>
        <div
          class="flex flex-col gap-2 mt-2 ml-8"
          v-for="(answer, index) in survey.custom_answers"
          :key="index"
        >
          <div class="text-sm">
            {{ answer }}
          </div>
        </div>
      </div>
      <hr class="my-2">
    </div>
    <div class="absolute right-32 top-1/4 flex ml-8 text-lg">
      <div class="font-bold mr-2">UUID</div>
      <div
        class="w-4/5 select-all"
        @click="copyUUIDToClipboard(params.data.surveys[0].uuid)"
      >{{ params.data.surveys[0].uuid }}</div>

    </div>
  </div>
</template>

<script>
export default {
  methods: {
    calculateVotingRate (option, options) {
      const totalVotes = options.reduce((value, opt) => value + opt.answers_count, 0)
      return parseFloat(((option.answers_count / (totalVotes === 0 ? 1 : totalVotes)) * 100).toFixed(0))
    },

    copyUUIDToClipboard (uuid) {
      navigator.clipboard.writeText(uuid)
        .then(() => {
          this.$buefy.notification.open({
            message: 'Identifiant unique copié dans le presse-papiers.',
            type: 'is-success',
            position: 'is-bottom',
            duration: 5000
          })
        })
    }
  }
}
</script>
